import "./App.css";
import Homepage from "./pages/Homepage";
import Contactpage from "./pages/Contactpage";
import Aboutpage from "./pages/Aboutpage";
import Servicepage from "./pages/Servicepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<Homepage />} path="/" />
          <Route element={<Contactpage />} path="/contact" />
          <Route element={<Aboutpage />} path="/about" />
          <Route element={<Servicepage />} path="/service" />
        </Routes>
      </Router>
    </>
  );
}

export default App;
