import bpa from "../assets/images/bpa.png";
import iaf from "../assets/images/iaf.png";
import ias from "../assets/images/ias.png";
import rir from "../assets/images/rir.png";
import map from "../assets/images/map.png";
import a from "../assets/images/a.png";
import k from "../assets/images/k.png";
import s from "../assets/images/s.png";
import ith from "../assets/images/ith.jpg";
import fth from "../assets/images/fth.jpg";
import ft from "../assets/images/ft.jpg";
import fo from "../assets/images/fo.jpg";
import io from "../assets/images/io.jpg";
import it from "../assets/images/it.jpg";
import ff from "../assets/images/ff.jpg";
import rt from "../assets/images/rt.jpg";
import ro from "../assets/images/ro.jpg";
import st from "../assets/images/st.jpg";
import sth from "../assets/images/sth.jpg";
import so from "../assets/images/so.jpg";
import legalOne from "../assets/images/legalOne.png";
import legalTwo from "../assets/images/legalTwo.png";

const assets = {
  legalOne,
  legalTwo,
  so,
  sth,
  st,
  ro,
  rt,
  ff,
  it,
  io,
  ith,
  fth,
  ft,
  fo,
  bpa,
  iaf,
  ias,
  rir,
  map,
  a,
  k,
  s,
};

export default assets;
